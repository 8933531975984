@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800&display=swap');

html{
  scroll-behavior: smooth;
}

body,
main {
  font-family: 'Nunito', sans-serif !important;
}

.box-shadow-style {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.box-shadow-profile {
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.05),
    0px 12px 16px -4px rgba(16, 24, 40, 0.1);
}

.profile-card:hover {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.css-1vyamtt-MuiStepLabel-labelContainer {
  color: #344054 !important;
  font-weight: 500 !important;
}
.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: #ba9ffe !important;
}

@keyframes spin-slow {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@-webkit-keyframes spin-slow {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

.animate-spin-slow {
  animation: spin-slow 10s linear infinite;
  -webkit-animation: spin-slow 10s linear infinite;
}

@media only screen and (max-width: 767px) {
  .rmsc .dropdown-container {
    width: 80vw;
  }
}

@media only screen and (max-width: 640px) {
  .css-1wf8b0h-MuiTabs-flexContainer,
  .css-heg063-MuiTabs-flexContainer {
    overflow-x: scroll !important;
    padding: 0 12px;
  }
  .css-101ok93-MuiButtonBase-root-MuiTab-root{
    padding: 12px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1071px) {
  .rmsc .dropdown-container {
    width: 250px;
  }
}

@media only screen and (min-width: 1072px) and (max-width: 1279px) {
  .rmsc .dropdown-container {
    width: 300px;
  }
}

@media only screen and (min-width: 1280px) {
  .rmsc .dropdown-container {
    width: 350px;
  }
}


.hero-section{
 background: linear-gradient(to bottom, #a37eff37, #FFF4F6);
}